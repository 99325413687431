import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  sort: getIcon('ic_sort'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  legends: getIcon('ic_legends'),
  masters: getIcon('ic_autoawesomemotion'),
  wizards: getIcon('ic_autoawesomemosaic'),
  bookings: getIcon('ic_apps'),
  accounts: getIcon('ic_accounts')
};

const agentsSidebarConfig = [
  {
    subheader: 'Book',
    items: [
      {
        title: 'Book',
        path: PATH_DASHBOARD.booking.root,
        icon: ICONS.ecommerce,
        children: [{ title: 'New Bookings', path: PATH_DASHBOARD.booking.searchengine }]
      }
    ]
  },
  {
    subheader: 'Manage Bookings',
    items: [
      {
        title: 'Bookings',
        path: PATH_DASHBOARD.managebookings.root,
        icon: ICONS.bookings,
        children: [{ title: 'Find Bookings', path: PATH_DASHBOARD.managebookings.manageBooking }]
      }
    ]
  },
  {
    subheader: 'Wizards',
    items: [
      {
        title: 'Wizards',
        path: PATH_DASHBOARD.wizards.root,
        icon: ICONS.wizards,
        children: [{ title: 'Client Consultants', path: PATH_DASHBOARD.wizards.clientemployee }]
      }
    ]
  },
  {
    subheader: 'Accounts',
    items: [
      {
        title: 'Accounts',
        path: PATH_DASHBOARD.accounts.root,
        icon: ICONS.accounts,
        children: [
          { title: 'Payments Received', path: PATH_DASHBOARD.accounts.payments },
          { title: 'Invoices', path: PATH_DASHBOARD.accounts.invoices }
        ]
      }
    ]
  },
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Reports',
        path: PATH_DASHBOARD.reports.root,
        icon: ICONS.dashboard,
        children: [
          { title: 'BI Report', path: PATH_DASHBOARD.reports.warehouse },
          { title: 'Client Tariff', path: PATH_DASHBOARD.reports.clienttariffreport },
          { title: 'Tariff Notifications', path: PATH_DASHBOARD.reports.tariffnotifications }
        ]
      }
    ]
  }
];

export default agentsSidebarConfig;
