import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import B2CLayout from '../layouts/B2C';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import { Currency } from '../schema/masters/Currency';
import { Department } from '../schema/masters/Department';
import { Designation } from '../schema/masters/Designation';
import { Country } from '../schema/masters/Country';
import { Module } from '../schema/masters/Module';
import { Market } from '../schema/masters/Market';
import { Destination } from '../schema/masters/Destination';
import { Category } from '../schema/masters/Category';
import { CabinClass } from '../schema/masters/CabinClass';
import { AccommodationType } from '../schema/masters/AccommodationType';
import { Facility } from '../schema/masters/Facility';
import { Supplier } from '../schema/masters/Supplier';
import { FlightMarkup } from '../schema/masters/FlightMarkup';
import { FlightMarkupWiz } from '../schema/wizards/FlightMarkupWiz';
import { City } from '../schema/masters/City';
import { Language } from '../schema/masters/Language';
import { Employee } from '../schema/masters/Employee';
import { ClientEmployee } from '../schema/masters/ClientEmployee';
import { UserGroup } from '../schema/masters/UserGroup';
import { Airport } from '../schema/masters/Airport';
import { DiscountType } from '../schema/masters/DiscountType';
import { Carrier } from '../schema/masters/Carrier';
import { CurrencyExchange } from '../schema/masters/CurrencyExchange';
import { HotelThirdpartyMarkup } from '../schema/masters/HotelThirdpartyMarkup';
import { HotelThirdpartyMarkupWiz } from '../schema/wizards/HotelThirdpartyMarkupWiz';
import { Product } from '../schema/masters/Product';
import { Client } from '../schema/masters/Client';
import { ClientAddress } from '../schema/masters/ClientAddress';
import { ClientGroup } from '../schema/masters/ClientGroup';
import { Vat } from '../schema/masters/Vat';
import { Tariff } from '../schema/masters/Tariff';

import { ActivityCategory } from '../schema/masters/ActivityCategory';
import { ActivitySubCategory } from '../schema/masters/ActivitySubCategory';
import { Supplement } from '../schema/masters/Supplement';
import { getSidebarConfig } from '../layouts/dashboard/SidebarConfig';
import agentsSidebarConfig from '../layouts/dashboard/AgentsSidebarConfig';
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // const { pathname } = useLocation();
  // const isDashboard = pathname.includes('/dashboard');
  const isDashboard = false;
  // const { user, b2bClient, uiType, hotelProduct } = useAuth();

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { userRights } = useAuth();
  const sidebarConfig = userRights && getSidebarConfig(userRights);

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> }
      ]
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout config={sidebarConfig} />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/dashboard/analytics" replace /> },
        { path: 'analytics', element: <Analytics /> },
        { path: 'menu', element: <Menu tabName="Legends" Config={sidebarConfig} /> },
        {
          path: 'wizards',
          children: [
            {
              path: '',
              element: <Menu tabName="Wizards" Config={sidebarConfig} />
            },
            { path: 'activity', element: <Activity /> },
            { path: 'hotel', element: <HotelWizard /> },
            { path: 'hotelallocation', element: <HotelAllocation /> },
            { path: 'client', element: <EntityList EntitySchema={Client} FormType="Master" /> },
            {
              path: 'flightmarkup',
              element: <EntityList EntitySchema={FlightMarkup} FormType="Wizard" />
            },
            {
              path: 'hotelthirdpartymarkup',
              element: <EntityList EntitySchema={HotelThirdpartyMarkup} FormType="Wizard" />
            },
            { path: 'employee', element: <EntityList EntitySchema={Employee} /> },
            { path: 'usergroup', element: <EntityList EntitySchema={UserGroup} /> },
            {
              path: 'ClientEmployee/:parentEntityId',
              element: <EntityList EntitySchema={ClientEmployee} FormType="Navigate" />
            },
            {
              path: 'ClientEmployee',
              element: <EntityList EntitySchema={ClientEmployee} FormType="Master" />
            },
            {
              path: 'flightmarkupdetails/:entityId',
              element: <EntityWizard EntitySchema={FlightMarkupWiz} />
            },
            {
              path: 'hotelthirdpartymarkup/:entityId',
              element: <EntityWizard EntitySchema={HotelThirdpartyMarkupWiz} />
            },
            { path: 'clienttariff', element: <ClientTariff /> },
            { path: 'activitydetails', element: <ActivityDetails /> },
            { path: 'hoteldetails', element: <HotelDetails /> }
          ]
        },
        {
          path: 'masters',
          children: [
            {
              path: '',
              element: <Menu tabName="Masters" Config={sidebarConfig} />
            },
            { path: 'clientgroup', element: <EntityList EntitySchema={ClientGroup} /> },
            { path: 'currencyexchange', element: <EntityList EntitySchema={CurrencyExchange} /> },
            { path: 'department', element: <EntityList EntitySchema={Department} /> },
            { path: 'designation', element: <EntityList EntitySchema={Designation} /> },
            { path: 'discounttype', element: <EntityList EntitySchema={DiscountType} /> },
            { path: 'supplement', element: <EntityList EntitySchema={Supplement} /> },
            { path: 'tariff', element: <EntityList EntitySchema={Tariff} /> },
            { path: 'market', element: <EntityList EntitySchema={Market} /> },
            { path: 'product', element: <EntityList EntitySchema={Product} /> },
            { path: 'supplier', element: <EntityList EntitySchema={Supplier} /> },
            { path: 'vat', element: <EntityList EntitySchema={Vat} /> }
          ]
        },
        {
          path: 'legends',
          children: [
            {
              path: '',
              element: <Menu tabName="Legends" Config={sidebarConfig} />
            },
            { path: 'airport', element: <EntityList EntitySchema={Airport} /> },
            { path: 'cabinclass', element: <EntityList EntitySchema={CabinClass} /> },
            { path: 'carrier', element: <EntityList EntitySchema={Carrier} /> },
            { path: 'currency', element: <EntityList EntitySchema={Currency} /> },
            { path: 'language', element: <EntityList EntitySchema={Language} /> },
            { path: 'city', element: <EntityList EntitySchema={City} /> },
            { path: 'country', element: <EntityList EntitySchema={Country} /> },
            { path: 'module', element: <EntityList EntitySchema={Module} /> },
            { path: 'market', element: <EntityList EntitySchema={Market} /> },
            { path: 'destination', element: <EntityList EntitySchema={Destination} /> },
            { path: 'category', element: <EntityList EntitySchema={Category} /> },
            { path: 'AccommodationType', element: <EntityList EntitySchema={AccommodationType} /> },
            { path: 'Facility', element: <EntityList EntitySchema={Facility} /> },
            { path: 'ActivityCategory', element: <EntityList EntitySchema={ActivityCategory} /> },
            {
              path: 'ActivitySubCategory',
              element: <EntityList EntitySchema={ActivitySubCategory} />
            }
          ]
        },
        {
          path: 'booking',
          children: [
            {
              path: '',
              element: <Menu tabName="Book" Config={sidebarConfig} />
            },
            { path: 'searchengine', element: <SearchEngine /> },
            { path: 'hotelsearch', element: <HotelSearch /> },
            { path: 'hotelinformation', element: <HotelInformation /> },
            { path: 'hotelcheckout', element: <HotelCheckout /> },
            { path: 'flightcheckout', element: <FlightCheckout /> },
            { path: 'flightsearch', element: <FlightSearch /> },
            { path: 'activitysearch', element: <ActivitySearch /> },
            { path: 'activityinformation', element: <ActivityInformation /> },
            { path: 'activitycheckout', element: <ActivityCheckout /> },
            { path: 'importpnr', element: <ImportPnr /> },
            { path: 'manualbooking', element: <ManualBooking /> },
            { path: 'yearlysearch', element: <YearlySearch /> },
            { path: 'debugsearch', element: <DebugSearch /> },
            { path: 'hotelpackagesearch', element: <HotelPackageSearch /> },
            { path: 'hotelpackageinformation', element: <HotelPackageInformation /> }
          ]
        },
        {
          path: 'reports',
          children: [
            {
              path: '',
              element: <Menu tabName="Reports" Config={sidebarConfig} />
            },
            { path: 'warehouse', element: <Warehouse /> },
            { path: 'activitypreparation', element: <ActivityPreparation /> },
            { path: 'hotelpreparation', element: <HotelPreparation /> },
            { path: 'clienttariffreport', element: <ClientTariffReport /> },
            { path: 'tariffnotifications', element: <TariffNotifications /> },
            { path: 'warehouse', element: <Warehouse /> },
            { path: 'audittrail', element: <AuditTrail /> },
            { path: 'logintrail', element: <LoginTrail /> }
          ]
        },
        {
          path: 'manage',
          children: [
            {
              path: '',
              element: <Menu tabName="Bookings" Config={sidebarConfig} />
            },
            { path: 'bookings', element: <ManageBooking /> },
            { path: 'bookings/:bookingId', element: <ManageBooking /> }
          ]
        },
        {
          path: 'find',
          children: [
            {
              path: '',
              element: <Menu tabName="Find" Config={sidebarConfig} />
            },
            { path: 'find', element: <Find /> }
          ]
        },
        {
          path: 'accounts',
          children: [
            {
              path: '',
              element: <Menu tabName="Accounts" Config={sidebarConfig} />
            },
            { path: 'payments', element: <Payments /> },
            { path: 'invoices', element: <Invoices /> },
            { path: 'carddetails', element: <CardDetails /> },
            { path: 'vcntransactions', element: <VcnTransactions /> }
          ]
        },
        {
          path: 'help',
          children: [
            {
              path: '',
              element: <Menu tabName="Help" Config={sidebarConfig} />
            },
            { path: 'help', element: <Help /> }
          ]
        },
        {
          path: 'settings',
          children: [
            {
              path: '',
              element: <Menu tabName="Settings" Config={sidebarConfig} />
            },
            { path: 'companydetails', element: <CompanyDetails /> },
            { path: 'settings', element: <Settings /> }
          ]
        }
      ]
    },
    // Main Routes
    /* {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }, */
    { path: '/', element: <Navigate to="/auth/login" replace /> }
  ]);
}

export function RouterB2C() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> }
      ]
    },
    {
      path: '/',
      element: <B2CLayout />,
      children: [
        { path: '', element: <SearchEngine /> },
        { path: 'hotelsearch', element: <HotelSearch /> },
        { path: 'hotelinformation', element: <HotelInformation /> },
        { path: 'hotelcheckout', element: <HotelCheckout /> },
        { path: 'flightcheckout', element: <FlightCheckout /> },
        { path: 'flightsearch', element: <FlightSearch /> },
        { path: 'activitysearch', element: <ActivitySearch /> },
        { path: 'activityinformation', element: <ActivityInformation /> },
        { path: 'activitycheckout', element: <ActivityCheckout /> },
        { path: 'hotelpackagesearch', element: <HotelPackageSearch /> },
        { path: 'hotelpackageinformation', element: <HotelPackageInformation /> },
        { path: 'mytrips', element: <MyTrips /> },
        { path: 'bookingdetails', element: <BookingDetails /> }
      ]
    }
  ]);
}

export function RouterB2B() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginB2B />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <LoginB2B /> }
      ]
    },
    { path: 'terms', element: <TermsAndConditions /> },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout config={agentsSidebarConfig} />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/dashboard/booking/searchengine" replace /> },
        { path: 'menu', element: <Menu tabName="Book" Config={agentsSidebarConfig} /> },
        {
          path: 'booking',
          children: [
            {
              path: '',
              element: <Menu tabName="Book" Config={agentsSidebarConfig} />
            },
            { path: 'searchengine', element: <SearchEngine /> },
            { path: 'hotelsearch', element: <HotelSearch /> },
            { path: 'hotelinformation', element: <HotelInformation /> },
            { path: 'hotelcheckout', element: <HotelCheckout /> },
            { path: 'flightcheckout', element: <FlightCheckout /> },
            { path: 'flightsearch', element: <FlightSearch /> },
            { path: 'activitysearch', element: <ActivitySearch /> },
            { path: 'activityinformation', element: <ActivityInformation /> },
            { path: 'activitycheckout', element: <ActivityCheckout /> },
            { path: 'hotelpackagesearch', element: <HotelPackageSearch /> },
            { path: 'hotelpackageinformation', element: <HotelPackageInformation /> },
            { path: 'importpnr', element: <ImportPnr /> },
            { path: 'manualbooking', element: <ManualBooking /> }
          ]
        },
        {
          path: 'wizards',
          children: [
            {
              path: '',
              element: <Menu tabName="Wizards" Config={agentsSidebarConfig} />
            },
            {
              path: 'ClientEmployee/:parentEntityId',
              element: <EntityList EntitySchema={ClientEmployee} FormType="Navigate" />
            },
            {
              path: 'ClientEmployee',
              element: <EntityList EntitySchema={ClientEmployee} FormType="Master" />
            }
          ]
        },
        {
          path: 'manage',
          children: [
            {
              path: '',
              element: <Menu tabName="Bookings" Config={agentsSidebarConfig} />
            },
            { path: 'bookings', element: <ManageBooking /> },
            { path: 'bookings/:bookingId', element: <ManageBooking /> }
          ]
        },
        {
          path: 'accounts',
          children: [
            {
              path: '',
              element: <Menu tabName="Accounts" Config={agentsSidebarConfig} />
            },
            { path: 'payments/:isnofify', element: <Payments /> },
            { path: 'invoices/:isnofify', element: <Invoices /> },
            { path: 'payments', element: <Payments /> },
            { path: 'invoices', element: <Invoices /> }
          ]
        },
        {
          path: 'reports',
          children: [
            {
              path: '',
              element: <Menu tabName="Reports" Config={agentsSidebarConfig} />
            },
            { path: 'warehouse', element: <Warehouse /> },
            { path: 'activitypreparation', element: <ActivityPreparation /> },
            { path: 'hotelpreparation', element: <HotelPreparation /> },
            { path: 'clienttariffreport', element: <ClientTariffReport /> },
            { path: 'tariffnotifications/:isnofify', element: <TariffNotifications /> },
            { path: 'tariffnotifications', element: <TariffNotifications /> }
          ]
        },
        {
          path: 'help',
          children: [
            {
              path: '',
              element: <Menu tabName="Help" Config={agentsSidebarConfig} />
            },
            { path: 'help', element: <Help /> }
          ]
        }
      ]
    },
    // Main Routes
    /* {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }, */
    { path: '/', element: <Navigate to="/auth/login" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const LoginB2B = Loadable(lazy(() => import('../pages/authentication/LoginB2B')));
// Dashboard
const Menu = Loadable(lazy(() => import('../pages/Menu')));
const ManageBooking = Loadable(lazy(() => import('../pages/managebooking/FindBookings')));
const Find = Loadable(lazy(() => import('../pages/Find')));
const Payments = Loadable(lazy(() => import('../pages/accounting/Payments')));
const Invoices = Loadable(lazy(() => import('../pages/accounting/Invoices')));
const Settings = Loadable(lazy(() => import('../pages/Settings')));
const Help = Loadable(lazy(() => import('../pages/Help')));

const EntityWizard = Loadable(lazy(() => import('../pages/dashboard/EntityWizard')));
const EntityList = Loadable(lazy(() => import('../pages/dashboard/EntityList')));
const SearchEngine = Loadable(lazy(() => import('../pages/booking/SearchEngine')));
const HotelSearch = Loadable(lazy(() => import('../pages/booking/HotelSearch')));
const HotelInformation = Loadable(lazy(() => import('../pages/booking/HotelInformation')));
const HotelCheckout = Loadable(lazy(() => import('../pages/booking/HotelCheckout')));
const FlightCheckout = Loadable(lazy(() => import('../pages/booking/FlightCheckout')));
const FlightSearch = Loadable(lazy(() => import('../pages/booking/FlightSearch')));
const ActivitySearch = Loadable(lazy(() => import('../pages/booking/ActivitySearch')));
const ActivityInformation = Loadable(lazy(() => import('../pages/booking/ActivityInformation')));
const ActivityCheckout = Loadable(lazy(() => import('../pages/booking/ActivityCheckout')));
const ImportPnr = Loadable(lazy(() => import('../pages/booking/ImportPnr')));
const ManualBooking = Loadable(lazy(() => import('../pages/booking/ManualBooking')));
const Warehouse = Loadable(lazy(() => import('../pages/reports/Warehouse')));
const ActivityPreparation = Loadable(lazy(() => import('../pages/reports/ActivityPreparation')));
const HotelPreparation = Loadable(lazy(() => import('../pages/reports/HotelPreparation')));
const AuditTrail = Loadable(lazy(() => import('../pages/reports/AuditTrail')));
const LoginTrail = Loadable(lazy(() => import('../pages/reports/LoginTrail')));
const MyTrips = Loadable(lazy(() => import('../pages/booking/MyTrips')));
const BookingDetails = Loadable(lazy(() => import('../pages/B2C/BookingDetails')));
const ClientTariffReport = Loadable(lazy(() => import('../pages/reports/ClientTariffReport')));
const TariffNotifications = Loadable(lazy(() => import('../pages/reports/TariffNotifications')));
const VcnTransactions = Loadable(lazy(() => import('../pages/accounting/VcnTransactions')));

const YearlySearch = Loadable(lazy(() => import('../pages/booking/YearlySearch')));
const DebugSearch = Loadable(lazy(() => import('../pages/booking/DebugSearch')));

const ClientTariff = Loadable(lazy(() => import('../pages/backoffice/ClientTariff')));
const Activity = Loadable(lazy(() => import('../pages/backoffice/Activity')));
const HotelWizard = Loadable(lazy(() => import('../pages/backoffice/Hotel')));
const ActivityDetails = Loadable(lazy(() => import('../pages/backoffice/ActivityDetails')));
const HotelDetails = Loadable(lazy(() => import('../pages/backoffice/HotelDetails')));
const HotelAllocation = Loadable(lazy(() => import('../pages/backoffice/HotelAllocation')));
const CardDetails = Loadable(lazy(() => import('../pages/accounting/CardDetails')));

const Analytics = Loadable(lazy(() => import('../pages/analytics/Analytics')));
// const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const CompanyDetails = Loadable(lazy(() => import('../pages/settings/CompanyDetails')));

const TermsAndConditions = Loadable(lazy(() => import('../pages/TermsAndConditions')));
// Main
// const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));

const HotelPackageSearch = Loadable(lazy(() => import('../pages/booking/HotelPackageSearch')));
const HotelPackageInformation = Loadable(
  lazy(() => import('../pages/booking/HotelPackageInformation'))
);
